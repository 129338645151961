export default {
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // Endpoints utils
  utilsPricingEndpoint: '/api/v1/utils/pricing',
  utilsCountriesEndpoint: '/api/v1/utils/countries',
  utilsProvinceEndpoint: '/local/province-list',
  utilsDistrictEndpoint: '/local/district-list',
  utilsWardEndpoint: '/local/ward-list',
  utilsVehicleTypesEndpoint: '/api/v1/utils/vehicle-types',
  utilsPaymentPeriodsEndpoint: '/api/v1/utils/payment-periods',
  utilsGendersEndpoint: '/api/v1/utils/genders',
  utilsUnitsEndpoint: '/api/v1/utils/units',
  utilsMeterTypesEndpoint: '/api/v1/utils/meter_types',
  utilsAbilitiesEndpoint: '/api/v1/utils/abilities',
  utilsCalculateQuantityEndpoint: '/api/v1/utils/calculate-quantity-with-date-range',

  // Endpoints
  loginEndpoint: '/user/login',
  registerEndpoint: '/api/v1/user/register',
  refreshEndpoint: '/api/v1/user/refresh-token',
  logoutEndpoint: '/api/v1/user/logout',

  // Endpoint Chat
  chatListChatAndContactEndpoint: '/api/v1/chat/list',
  chatDetailEndpoint: '/api/v1/chat/detail',
  chatProfileEndpoint: '/api/v1/chat/profile',
  chatSendMessageEndpoint: '/api/v1/chat/send-message',

  // Endpoint user
  userProfileEndpoint: '/user/me',
  userProfileUpdateEndpoint: '/api/v1/user/update',
  userChangePasswordEndpoint: '/api/v1/user/change-password',
  userVerifyEmailEndpoint: '/api/v1/user/verify-email',
  userRequestVerifyEmailEndpoint: '/api/v1/user/request-verify-email',
  userRequestMissCallOTPEndpoint: '/api/v1/user/request-miss-call-otp',
  userResetPasswordWithOTPEndpoint: '/api/v1/user/reset-password-with-otp',
  userVerifyPhoneEndpoint: '/api/v1/user/verify-phone',
  userSetPasswordEndpoint: '/user/update-account-password',

  // Endpoint dashboard
  dashboardEndpoint: '/dashboard',

  // Enpoint configuration
  userConfigurationEndpoint: '/api/v1/user-configuration/get',
  userConfigurationUpdateEndpoint: '/api/v1/user-configuration/update',
  emailConfigurationEndpoint: '/api/v1/email-configuration/get',
  emailConfigurationUpdateEndpoint: '/api/v1/email-configuration/update',
  apartmentConfigurationEndpoint: '/api/v1/apartment-configuration/get',
  apartmentConfigurationUpdateEndpoint: '/api/v1/apartment-configuration/update',

  // Report

  reportPropertyGeneral: '/api/v1/report/property/general',
  reportPropertyCounting: '/api/v1/report/property/counting',
  reportPropertyDetail: '/api/v1/report/property/detail',
  reportFinanceByYear: '/api/v1/report/finance/chart-by-year',
  reportFinanceCashFlow: '/api/v1/report/finance/cashflow',
  reportFinanceCounting: '/api/v1/report/finance/counting',
  reportFinanceDeposit: '/api/v1/report/finance/deposit',
  reportFinancePrepaid: '/api/v1/report/finance/prepaid',

  // Prepaid notification
  prepaidListEndpoint: '/api/v1/prepaid/list',
  prepaidCreateEndpoint: '/api/v1/prepaid/create',
  prepaidUpdateEndpoint: '/api/v1/prepaid/update',
  prepaidDeleteEndpoint: '/api/v1/prepaid/delete',
  prepaidDetailByContractEndpoint: '/api/v1/prepaid/detail-by-contract',
  prepaidRemainByContractEndpoint: '/api/v1/prepaid/remain-prepaid-by-contract',

  // Payment
  paymentCreatePaymentUrl: '/api/v1/payment/create-payment-url',
  paymentValidateResultUrl: '/api/v1/payment/validate-result',

  // Plan
  planListEndpoint: '/api/v1/plan/list',
  planDetailEndpoint: '/api/v1/plan/detail',

  // Order
  orderListEndpoint: '/api/v1/order/list',
  orderCreateEndpoint: '/api/v1/order/create',
  orderUpdateEndpoint: '/api/v1/order/update',
  orderDeleteEndpoint: '/api/v1/order/delete',

  // Doctor
  doctorListingEndPoint: '/doctor/listing',
  doctorListEndPoint: '/doctor/list',
  doctorPersonalListEndPoint: '/doctor/suggest',
  doctorListForMedicalFacilityEndPoint: '/doctor/listing-for-admin-medical-facility',
  doctorCreateEndPoint: '/doctor/add',
  doctorUpdateEndPoint: '/doctor/',

  // Patient
  patientListEndPoint: '/customer/listing',
  patientSelectListEndPoint: '/customer/select-list',
  patientListForMedicalFacilityEndPoint: '/customer/listing-for-admin-medical-facility',
  patientCreateEndPoint: '/customer/create',
  patientUpdateEndPoint: '/customer',
  patientDeleteEndPoint: '/customer',
  patientExportEndPoint: '/customer/export',

  // Customer Group
  customerGroupListEndPoint: '/customer-group/select-list',

  // Medical Facility
  facilityListEndPoint: '/medical-facility/listing',
  facilitySuggestEndPoint: '/medical-facility/listing',
  facilityUpdateEndPoint: '/medical-facility',
  facilityDeleteEndPoint: '/medical-facility',
  facilityCreateEndPoint: '/medical-facility/add',

  facilityAdminListEndPoint: '/medical-facility/admin-listing',
  facilityAdminCreateEndPoint: '/user/add-medical-facility-admin',
  facilityAdminUpdateEndPoint: '/user',

  // Pharmacy
  pharmacyListEndPoint: '/pharmacy/listing',
  pharmacyCreateEndPoint: '/pharmacy/add',
  pharmacyUpdateEndPoint: '/pharmacy',
  pharmacyDeleteEndPoint: '/pharmacy',

  // Attendance
  attendanceListEndPoint: '/work-log/listing',
  attendanceExportEndPoint: '/work-log/export',

  // Meeting
  meetingListEndPoint: '/meeting/listing',
  meetingListForMedicalFacilityEndPoint: '/meeting/listing-for-medical-facility',
  meetingListFromWorkLogEndPoint: '/meeting/work-log-listing',

  // Prescription
  prescriptionListEndPoint: '/prescription/listing',

  // Advertisement
  advertisementListEndPoint: '/advertisement/listing',
  advertisementCreateEndPoint: '/advertisement/add',
  advertisementUpdateEndPoint: '/advertisement',
  advertisementDeleteEndPoint: '/advertisement',
  // Payment
  paymentListEndPoint: '/payment/listing',

  // Promotion
  promotionListEndPoint: '/promotion-policy/listing',
  promotionCreateEndPoint: '/promotion-policy/add',
  promotionUpdateEndPoint: '/promotion-policy',
  promotionDeleteEndPoint: '/promotion-policy',

  // Voucher
  voucherListEndPoint: '/voucher/listing-for-admin',
  voucherListForMedicalFacilityEndPoint: '/voucher/listing',
  voucherCreateEndPoint: '/voucher/add',
  voucherUpdateEndPoint: '/voucher',

  // Code
  codeListEndPoint: '/pricing-code/listing',

  // Pricing
  pricingListEndPoint: '/pricing/listing',
  pricingCreateEndPoint: '/pricing/add',
  pricingUpdateEndPoint: '/pricing',
  pricingDeleteEndPoint: '/pricing',
  pricingSuggestEndPoint: '/pricing/suggest',

  // Department
  departmentListEndPoint: '/specialist-category/listing',
  departmentSuggestEndPoint: '/specialist-category/suggest',
  departmentCreateEndPoint: '/specialist-category/add',
  departmentUpdateEndPoint: '/specialist-category',
  departmentDeleteEndPoint: '/specialist-category',

  // Specialist
  specialistListEndPoint: '/specialist-sub-category/listing',
  specialistSuggestEndPoint: '/specialist-sub-category/suggest',
  specialistCreateEndPoint: '/specialist-sub-category/add',
  specialistUpdateEndPoint: '/specialist-sub-category',
  specialistDeleteEndPoint: '/specialist-sub-category',

  // Blood
  bloodListEndPoint: '/blood/listing',
  bloodCreateEndPoint: '/blood/add',
  bloodUpdateEndPoint: '/blood',
  bloodDeleteEndPoint: '/blood',

  // Service
  serviceListEndPoint: '/providing-service/listing',
  serviceCreateEndPoint: '/providing-service/add',
  serviceUpdateEndPoint: '/providing-service',
  serviceDeleteEndPoint: '/providing-service',

  // Disease
  diseaseListEndPoint: '/diseases/listing',
  diseaseCreateEndPoint: '/diseases/add',
  diseaseUpdateEndPoint: '/diseases',
  diseaseDeleteEndPoint: '/diseases',

  // Drug
  drugListEndPoint: '/drug/listing',
  drugCreateEndPoint: '/drug/add',
  drugUpdateEndPoint: '/drug',
  drugDeleteEndPoint: '/drug',

  // Faq
  faqListEndPoint: '/pre-medical-question/listing',
  faqCreateEndPoint: '/pre-medical-question/add',
  faqUpdateEndPoint: '/pre-medical-question',
  faqDeleteEndPoint: '/pre-medical-question',

  // File
  fileUploadSingleEndPoint: '/media/upload-multi-size',

  // Exp
  expListEndPoint: '/experience/listing-for-admin',
  expCreateEndPoint: '/experience/add-for-admin',
  expUpdateEndPoint: '/experience/update-for-admin',
  expDeleteEndPoint: '/experience/delete-for-admin',

  // Education
  educationListEndPoint: '/education/listing-for-admin',
  educationCreateEndPoint: '/education/add-for-admin',
  educationUpdateEndPoint: '/education/update-for-admin',
  educationDeleteEndPoint: '/education/delete-for-admin',

  // Education
  configurationListEndPoint: '/configuration/listing',
  configurationCreateEndPoint: '/configuration/add',
  configurationUpdateEndPoint: '/configuration',
  configurationDeleteEndPoint: '/configuration',

};
