import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import { canNavigate } from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';
import dashboard from './routes/dashboard';

import settings from './routes/settings';
import profile from './routes/profile';
import auth from './routes/auth';
import miscellaneous from './routes/miscellaneous';
import user from './routes/user';
import place from './routes/place';
import operation from './routes/operation';
import subscription from './routes/subscription';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'doctor-list' } },
    ...dashboard,
    ...user,
    ...place,
    ...operation,
    ...subscription,
    ...profile,
    ...settings,
    ...auth,
    ...miscellaneous,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  console.log(isLoggedIn);

  if (!canNavigate(to)) {
    console.log(to);
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' });

    // If logged in => not authorized
    return next({ name: 'unauthorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
